import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {
  "title": "Native American Lands | Revenue",
  "includeToc": true,
  "description": "Natural resources are increasingly a key source of income for many Native American tribes and individuals.",
  "tags": ["How revenue works", "production", "renewable energy", "geothermal", "wind", "water power", "biomass", "Native American", "Indian", "tribal"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Table = makeShortcode("Table");
const TableHead = makeShortcode("TableHead");
const TableRow = makeShortcode("TableRow");
const TableCell = makeShortcode("TableCell");
const TableBody = makeShortcode("TableBody");
const GlossaryTerm = makeShortcode("GlossaryTerm");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "/how-revenue-works/"
      }}>{`How revenue works`}</a>{` /`}</p>

    <h1 {...{
      "id": "Revenue-from-Natural-Resources-on-Native-American-Land",
      "style": {
        "position": "relative"
      }
    }}>{`Revenue from Natural Resources on Native American Land`}</h1>
    <p>{`Natural resources are a key source of income for many Native American tribes and individuals.`}</p>
    <br />
Natural resource extraction on Native American land generates revenue. This process is similar to the <a href="../revenues">federal land process</a>. Each phase of the production process can result in revenue for the Native American resource owners. For instance, companies pay for bonuses to secure rights, rents during pre-production, and royalties during production.
    <h2 {...{
      "id": "Revenue-collection",
      "style": {
        "position": "relative"
      }
    }}>{`Revenue collection`}</h2>
    <p>{`Several organizations within the Department of the Interior (DOI) have roles and responsibilities in collecting revenue from production on Native American land:`}</p>
    <Table aria-label="Collecting revenue from production" size="small" mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      <TableCell rowSpan="2" mdxType="TableCell">Resource</TableCell>
      <TableCell colSpan="3" mdxType="TableCell">Stage of Production</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Securing a lease or claim</TableCell>
      <TableCell mdxType="TableCell">Pre-production</TableCell>
      <TableCell mdxType="TableCell">During production</TableCell>
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Fluid minerals</TableCell>
      <TableCell mdxType="TableCell">BIA bills, collects, and accounts for bonuses.</TableCell>
      <TableCell mdxType="TableCell">BIA bills, collects, and accounts for rent on a lease prior to BLM notice of first production.</TableCell>
      <TableCell mdxType="TableCell">After production starts, ONRR collects, accounts for, and deposits rents, royalties, and compliance-based collections. BIA collects other revenues, such as payment for surface damages.</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Solid minerals</TableCell>
      <TableCell mdxType="TableCell">BIA bills, collects, and accounts for bonuses.</TableCell>
      <TableCell mdxType="TableCell">BIA bills, collects, and accounts for rent prior to production.</TableCell>
      <TableCell mdxType="TableCell">BIA bills, collects, and accounts for rent during production. Companies submit royalty and production reports to ONRR, but ONRR may not collect royalty payments unless BIA requests it. Companies deposit payments into BTFA or Native-American-owned lockboxes or mail checks to individual allottees. BIA collects other revenues, such as payments for water or surface damages.</TableCell>
    </TableRow>
  </TableBody>
    </Table>
    <p>{`Exceptions exist to this process. For example, a tribe can collect and account for all payments except the bonus. This is known as tribal lease direct payment. Individual mineral owners (allottees) may request that payments be made directly to them. The Bureau of Indian Affairs (BIA) approves or denies these direct pay requests. The amounts paid for extraction on tribal lands vary by tribe and are not available to the public.`}</p>
    <br />
In the case of an <GlossaryTerm mdxType="GlossaryTerm">IMDA</GlossaryTerm> agreement, the tribe collects taxes and non-trust revenue.
    <h2 {...{
      "id": "Revenue-distribution",
      "style": {
        "position": "relative"
      }
    }}>{`Revenue distribution`}</h2>
    <p>{`When the federal government collects payments, these are generally deposited into trust accounts managed by the Bureau of Trust Funds Administration (BTFA). When deposits are made into a BTFA-owned lockbox, BTFA deposits the funds into a trust account. When deposits are made into a tribal-owned lockbox, the tribe receives the funds directly.`}</p>
    <br />
The Office of Natural Resources Revenue (ONRR) provides a financial distribution report called an Explanation of Payment (EOP) to tribes. For allottees, ONRR provides the collection information to BIA to create the EOP. The report outlines the distribution for each mineral owner. BTFA then transfers funds from the BTFA treasury account to the mineral owner’s individual account. BTFA can also pay the owner via check or automated clearing house (for electronic funds transfer).
    <h2 {...{
      "id": "Revenue-data",
      "style": {
        "position": "relative"
      }
    }}>{`Revenue data`}</h2>
    <p>{`For all Native American land, the federal government only releases natural resource extraction and revenue information in aggregate. Specific data on Native American revenues are confidential and proprietary. Treaties, laws, and regulations dictate what data the government can release.`}</p>
    <p><a parentName="p" {...{
        "href": "/downloads/federal-revenue-by-location/"
      }}>{`Documentation about the federal and Native American revenue dataset`}</a></p>
    <Link href='/query-data/?dataType=Revenue&landType=Native%20American' linkType='FilterTable' mdxType="Link">Query Native American revenue data</Link>
    <h2 {...{
      "id": "Audits-and-assurances",
      "style": {
        "position": "relative"
      }
    }}>{`Audits and assurances`}</h2>
    <p>{`Native American revenue data, like federal revenue data, is subject to standards, audits, and assurances. These include:`}</p>
    <ul>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://www.onrr.gov/about/interagency?tabs=standard-operating-procedures-sops"
        }}>{`Onshore Energy and Mineral Lease Management Interagency Standard Operating Procedures `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` outline the procedures that BLM, ONRR, and BIA follow. These agencies verify and enforce compliance for production and revenue collection from Native American lands.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://www.bia.gov/sites/default/files/dup/assets/bia/ois/pdf/idc017333.pdf"
        }}>{`Indian Self-Determination and Education Assistance Act (Public Law 93-638) (PDF) `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` and Section 202 of the `}<a parentName="li" {...{
          "href": "https://www.onrr.gov/references/statutes"
        }}>{`Federal Oil & Gas Royalty Management Act of 1982 `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` (FOGRMA) outline how DOI may contract with a tribe to perform compliance activities.`}</li>
      <li parentName="ul">{`The `}<a parentName="li" {...{
          "href": "https://www.doi.gov/ost/publications"
        }}>{`Bureau of Trust Fund Administration (BTFA) Annual Reports `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` include the annual auditor’s report for tribal and other trust funds. These reports are under the `}<a parentName="li" {...{
          "href": "https://www.doi.gov/sites/doi.gov/files/migrated/ost/trust_documents/upload/American-IndianTrustFundManagementReformActof1994.pdf"
        }}>{`American Indian Trust Fund Management Reform Act of 1994 (PDF) `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      